const getColorFromTag = (tag) => {
  switch (tag) {
    case 'dev':
      return '#3867d6'
    case 'misc':
      return '#8854d0'
    case 'viz':
      return '#1e8449'
    case 'soft':
      return '#c0392b'
    case 'web':
      return '#9d6308'
    case 'py':
      return '#1B1464'
    case 'data':
      return '#c44569'
    default:
      return '#4b6584'
  }
}

export default getColorFromTag